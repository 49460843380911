/*
 * @Author: hys
 * @Date: 2023-07-16 04:54:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-11-13 02:15:01
 * @Description: 请填写简介
 */
import axios from "axios";

const http = axios.create({
  // 通用请求的地址前缀
  baseURL: "/api",
  headers: { "content-type": "application/json;charset=utf-8" },
  timeout: 100000,
  withCredentials: false, // 跨域请求是否需要携带 cookie
});

// 请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做什么
    return config;
  },
  function (error) {
    // 对请求错误做什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // 对响应数据做什么
    return response;
  },
  function (error) {
    // 对响应错误做什么
    return Promise.reject(error);
  }
);

export default http;
