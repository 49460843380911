/*
 * @Author: hys
 * @Date: 2024-11-10 00:52:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025-01-08 23:53:06
 * @Description: 请填写简介
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../Views/Main";
import fengPing from "../Views/fengPing"; 
import DP1 from "../Views/DP1";
import DP2 from "../Views/DP2";
import Login from "../Views/Login.vue";
import Cookie from "js-cookie";

Vue.use(VueRouter);

const routes = [
  // 主路由
  {
    path: "/",
    name: "Main",
    component: Main,
    redirect: "/home",
    children: [
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/fengPing",
    name: "fengPing",
    component: fengPing,
  },
  {
    path: "/DP1",
    name: "DP1",
    component: DP1,
  },
  {
    path: "/DP2",
    name: "DP2",
    component: DP2,
  },
];

const router = new VueRouter({
  routes,
});

// 路由守卫:全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 获取token
  const token = Cookie.get("token");
  if (!token && to.name !== "login") {
    next({ name: "login" });
  } else if (token && to.name === "login") {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
