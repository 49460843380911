<template>
  <div class="data1">
    <div class="data1_content">
      <div class="data1_title">
        <dv-decoration-3 style="width: 250px; height: 30px" />农业数据管理平台<dv-decoration-3
          style="width: 250px; height: 30px" />
      </div>
      <dv-decoration-5 dur="2" style="width: 100vw; height: 20px; margin: -30px auto 30px" />
      <div class="data1_time">当前时间：{{ nowTime }}</div>
      <div class="data1_mainView">
        <div class="data1_left">
          <dv-decoration-11
            style="width:200px;height:60px;margin: 10px;color: wheat; font-size: 18px; font-weight: bold;">种植情况</dv-decoration-11>
          <dv-border-box-10 style="height: 200px;padding: 10px;">
            <p
              style="font-size: 18px;color: white;margin-top: 20px;margin-left: 20px;line-height: 30px;font-weight: 400;">
              简介：蔬菜园区种植面积较大，种植面积占全区总面积的80%以上，其中玉米、大豆、小麦、水稻等作物种植面积占比分别为30%、20%、20%、10%。

              主要作物种植面积：玉米：1200万亩，大豆：1000万亩，小麦：1000万亩，水稻：500万亩。

              主要作物种植面积占比：玉米：30%，大豆：20%，小麦：20%，水稻</p>



          </dv-border-box-10>
          <dv-decoration-11
            style="width:200px;height:60px;margin: 10px;color: wheat; font-size: 18px; font-weight: bold;">生产情况</dv-decoration-11>
          <dv-border-box-10>
            <div style="display: flex;"><dv-active-ring-chart :config="hysconfig1" style="width:300px;height:300px" />
              <dv-conical-column-chart :config="hysconfig1"
                style="width:600px;height:280px;display: flex;justify-content: center;align-items: center;margin-top: 50px;" />
            </div>
          </dv-border-box-10>
        </div>
        <div class="data1_center"
          style="height: 40%;display: flex;flex-direction: column;justify-content: flex-end;margin-top: 420px;margin-right: 20px;">
          <!-- <dv-decoration-11
            style="width:200px;height:60px;margin: 10px;color: wheat; font-size: 18px; font-weight: bold;">销售情况</dv-decoration-11>
          <dv-border-box-10>dv-border-box-10</dv-border-box-10> -->
        </div>
        <div class="data1_right">
          <dv-decoration-11
            style="width:200px;height:60px;margin: 10px;color: wheat; font-size: 18px; font-weight: bold;">农资使用</dv-decoration-11>
          <dv-border-box-10 style="margin-bottom: 10px">
            <div
              style="display: flex;justify-content: space-evenly;margin-top: 40px; margin-bottom: 20px; margin-left: -100px;font-size: 18px;font-weight: bold;color: aliceblue;">
              <div>名称</div>
              <div>数量</div>
              <div>使用时间</div>
            </div>
            <dv-scroll-board :config="hysconfig3" style="width:560px;height:160px;margin: 0 auto;
            padding-top: 15px;" />
          </dv-border-box-10>
          <dv-decoration-11
            style="width:200px;height:60px;margin: 10px;color: wheat; font-size: 18px; font-weight: bold;">三品一标</dv-decoration-11>
          <dv-border-box-10 style="margin:0 auto;width: 600px;height: 220px;">
            <dv-capsule-chart :config="hysconfig" style="width: 100%; height: 180px;margin-top: 20px;" />
          </dv-border-box-10>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { h } from 'vue';

function timestampToTime(timestamp) {
  // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var date = new Date(timestamp);
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

export default {
  data() {
    return {
      nowTime: "",
      hysconfig3: {
        data: [
          ['三酮类', '22', '2025-01-01'],
          ['咪唑啉酮', '31', '2025-01-02'],
          ['苯达松', '24', '2025-01-03'],
          ['丁草胺', '56', '2025-01-04'],
          ['西草净', '12', '2025-01-05'],
          ['三酮类', '22', '2025-01-01'],
          ['咪唑啉酮', '31', '2025-01-02'],
          ['苯达松', '24', '2025-01-03'],
          ['丁草胺', '56', '2025-01-04'],
          ['西草净', '12', '2025-01-05'],
          ['三酮类', '22', '2025-01-01'],
        ],
      },
      hysconfig: {
        data: [
          {
            name: "无公害食品",
            value: 167,
          },
          {
            name: "绿色食品",
            value: 67,
          },
          {
            name: "有机食品",
            value: 123,
          }
        ]
      },
      hysconfig1: {
        data: [
          {
            name: "辣椒/吨",
            value: 167,
          },
          {
            name: "白菜/吨",
            value: 67,
          },
          {
            name: "萝卜/吨",
            value: 123,
          },
          {
            name: "青菜/吨",
            value: 123,
          }
        ],
        columnColor: "rgba(0, 255, 179, 0.8)",
        showValue: true,
        fontSize: 16,
      },

    };
  },
  created() {
    this.nowTime = timestampToTime(new Date());
    setInterval(() => {
      this.nowTime = timestampToTime(new Date());
    }, 1000);
  },
  mounted() { },
  methods: {},
};
</script>
<style scoped lang="less">
.data1 {
  // background-image: url("http://qny.hyszhny.com/web/data1/data1_bgc.png");
  background: radial-gradient(rgba(228, 225, 225, 0.01), rgba(12, 12, 15, 0.9)),
    url("http://qny.hyszhny.com/web/data1/background-ws.jpg") no-repeat 0% 20% / cover;
  width: 100vw;
  height: 100vh;
  background-color: beige;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data1_content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .data1_title {
    margin: 5px;
    height: 80px;
    // background: url(http://qny.hyszhny.com/web/data1/data1_title.png);
    background-size: 100% auto;
    color: #ebf1ee;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    text-align: center;
    line-height: 100px;
    display: flex;
    justify-content: center;
    align-items: last baseline;
  }

  .data1_time {
    text-align: center;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #f60e0a;
    margin-left: 1300px;
    // margin-right: 10px;
  }

  .data1_mainView {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    margin-top: 20px;

    .data1_left {
      margin-left: 20px;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .data1_center {
      width: 40%;
      background-size: 100% auto;
      display: flex;
      flex-direction: column;
      // height: 100%;
    }

    .data1_right {
      margin-right: 20px;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
}
</style>
