/*
 * @Author: hys
 * @Date: 2023-07-16 04:54:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025-01-04 21:55:06
 * @Description: 请填写简介
 */
import Cookie from "js-cookie";

export default {
  state: {
    isCollapse: false, //导航栏是否折叠
    tabList: [
      {
        path: "/",
        name: "home",
        label: "首页",
        icon: "s-home",
        url: "Home/Home",
      },
    ],
    menu: [], //不同用户的菜单
  },
  mutations: {
    // 修改导航栏展开和收起的方法
    CollapseMenu(state) {
      state.isCollapse = !state.isCollapse;
    },
    // 更新面包屑的数据
    SelectMenu(state, item) {
      // 如果点击的不在面包屑数据中,则添加
      const index = state.tabList.findIndex((val) => val.name === item.name);
      if (index === -1) {
        state.tabList.push(item);
      }
    },
    // 删除tag:删除tabList中对应的item
    closeTag(state, item) {
      // 要删除的是state.tabList中的item
      const index = state.tabList.findIndex((val) => val.name === item.name);
      state.tabList.splice(index, 1);
    },
    // 设置不同用户的菜单
    setMenu(state, val) {
      state.menu = val;
      Cookie.set("menu", JSON.stringify(val));
    },

    // 动态添加路由
    addMenu(state, router) {
      // 判断Cookie
      if (!Cookie.get("menu")) return;

      const menu = JSON.parse(Cookie.get("menu"));
      state.menu = menu;
      const menuArray = [];

      // 组装路由
      menu.forEach((item) => {
        item.component = () => import(`../Views/${item.url}`);
        menuArray.push(item);
      });
      menuArray.forEach((item) => {
        router.addRoute("Main", item);
      });
    },
  },
};
