<!--
 * @Author: hys
 * @Date: 2024-11-08 20:41:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-12-25 21:03:47
 * @Description: 请填写简介
-->
<template>
    <div class="main">
        <div v-for="(item, index) in 6">
            <video class="play-video" @click="playVideos" controls preload="auto" width="100%" height="100%">
                <source :src="item.playVideoUrl" type="video/webm" autoplay muted />
            </video>
        </div>
    </div>
</template>
<script>
import http from "../utils/request";
export default {
    data() {
        return {
            playVideoUrlList: [],
        };
    },
    mounted() {
        // 获取视频地址
        const equipmentId = this.$route.params.equipmentId;
        let a=this.$route.query.videoNum;
        http.get("api/hkJK/getVideoUrl?equipmentId=" + equipmentId).then((res) => {
            this.playVideoUrl = res.data;
        });
    },
    methods: {
        playVideos() {
            const video = document.querySelector(".play-video");
            video.play();
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    height: 100vh;
    width: 100vw;
    background-color: #101010;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    margin: 20px;
    gap: 10px;
    .play-video {
        width: 100%;
        height: 450px;
        object-fit: cover;
        margin: auto;
    }
}
</style>